import type { ComponentPropsWithoutRef, JSX, MouseEventHandler } from 'react';
import { SVG } from '@inkd/ui';
import type { GeneratedSVGNames } from '@inkd/svgs';
import { type ShoppingCart } from '@inkd/xstate';

type ItemInCart = ShoppingCart[number];

export interface CartItemProps {
  cartItem: Omit<
    ItemInCart,
    | 'cardId'
    | 'cardMainImageDescription'
    | 'cardPreviewMainPhotoUrl'
    | 'fontSelection'
    | 'giftCardDenomination'
    | 'giftCardEmail'
    | 'giftCardId'
    | 'giftCardImageSrc'
    | 'signature'
  >;
  handlers: {
    edit: () => void;
    add: () => void;
    subtract: () => void;
    markForDeletion: () => void;
    undoMarkForDeletion: () => void;
    deleteImmediately: () => void;
  };
  /** Pass a component that will render an image. use an <img /> tag on the kiosk app and use the Next.js image component on the web/mobile app */
  imageRenderer: (props: { className: string }) => JSX.Element;
}

export function CartItem({
  cartItem: {
    cardName,
    cardPrice,
    giftCardPrice,
    giftCardVendorName,
    markedForDeletion,
    message,
    quantity,
  },
  handlers,
  imageRenderer,
}: CartItemProps) {
  if (markedForDeletion) {
    return (
      <div className='web:m-b-6 m-b-12 bg-surface-active p-i-16 p-b-8 web:gap-x-4 web:gap-y-2 web:p-i-3 web:p-b-3 web:rounded-sm web:grid-cols-[repeat(3,auto)] grid grid-cols-[1fr_auto_auto] items-center gap-x-8 rounded-md'>
        <p className='text-body web:col-span-3 web:[grid-row:1]'>
          Confirm Deletion: <span className='font-bold'>{cardName}</span>
        </p>
        <TinySecondaryButton
          text='Cancel'
          onClick={handlers.undoMarkForDeletion}
        />
        <TinySecondaryButton
          text='Confirm'
          onClick={handlers.deleteImmediately}
        />
      </div>
    );
  }

  return (
    <div
      /**
       * @see {@link `packages/config/tailwind/plugins/assortedUtilityClasses.ts`}
       * file for the `.cart-item` class that implements the `grid-template-areas`
       * property that sets up the grid for this component. We have to update that
       * property on screen sizes smaller than `375px` to handle a small edge case,
       * so it has to be defined in that file so we have access to a media query that
       * doesn't produce an _impossible_ to read class here
       */
      className='web:gap-x-3 web:gap-y-2 p-b-12 web:p-b-6 web:grid-cols-[minmax(50px,100px)_1fr_auto] cart-item border-be border-tonal-30 last-of-type:border-be-0 grid auto-rows-auto grid-cols-[minmax(112px,168px)_1fr_auto] gap-x-8 gap-y-4'
    >
      {imageRenderer({
        className: 'object-center object-contain [grid-area:image]',
      })}

      <h3 className='text-body [grid-area:name]'>{cardName}</h3>

      <p className='text-body font-bold [grid-area:price] max-[375px]:self-center min-[376px]:justify-self-end'>
        $
        {giftCardPrice
          ? ((cardPrice + giftCardPrice) * quantity).toFixed(2)
          : (cardPrice * quantity).toFixed(2)}
      </p>

      <div className='web:gap-y-1 flex flex-col gap-y-2 text-sm [grid-area:details]'>
        {message ? <p className='line-clamp-1'>{message}</p> : null}
        {typeof giftCardVendorName === 'string' &&
        typeof giftCardPrice === 'number' ? (
          <>
            <p className='line-clamp-1'>
              {`- $${cardPrice.toFixed(2)} Greeting Card`}
            </p>
            <p className='line-clamp-1'>
              {`- $${giftCardPrice.toFixed(2)} ${giftCardVendorName} Gift Card`}
            </p>
          </>
        ) : null}
      </div>

      <div className='web:gap-x-4 flex items-center gap-x-8 [grid-area:quantity]'>
        <TinySecondaryButton
          icon='Pencil'
          text='Edit'
          onClick={handlers.edit}
        />

        <div
          className='web:gap-x-2 flex items-center gap-x-4'
          data-cy='Quantity buttons'
        >
          <TinySecondaryButton
            icon={quantity === 1 ? 'Bin' : 'Minus'}
            onClick={
              quantity === 1 ? handlers.markForDeletion : handlers.subtract
            }
          />
          <p
            className='text-body web:min-w-[1rem] min-w-[2.5rem] text-center font-bold'
            data-cy='Quantity'
          >
            {quantity}
          </p>
          <TinySecondaryButton icon='Plus' onClick={handlers.add} />
        </div>
      </div>
    </div>
  );
}

interface TinySecondaryButtonProps extends ComponentPropsWithoutRef<'button'> {
  icon?: GeneratedSVGNames;
  onClick: MouseEventHandler<HTMLButtonElement>;
  text?: string;
}

function TinySecondaryButton({
  icon,
  onClick,
  text,
}: TinySecondaryButtonProps) {
  return (
    <button
      aria-label={text ? undefined : icon}
      className={`border-tonal-60 web:border-[0.15rem] web:gap-x-1 web:[&_svg]:w-4 web:[&_svg]:h-4 web:rounded-[0.375rem] text-ui-sm flex w-max items-center justify-center gap-x-2 rounded-[0.75rem] border-[0.25rem] bg-transparent hover:bg-white focus-visible:bg-white [&_svg]:h-8 [&_svg]:w-8 ${
        text ? 'p-i-8 p-b-3 web:p-i-4 web:p-b-2' : 'web:p-2 p-3'
      }`}
      onClick={onClick}
    >
      {icon ? <SVG name={icon} /> : null}
      {text ? <p>{text}</p> : null}
    </button>
  );
}
