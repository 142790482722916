/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo, useState } from 'react';
import type { ComponentPropsWithoutRef, RefObject } from 'react';
import { SimpleKeyboard } from 'react-simple-keyboard';
import Keyboard from 'react-simple-keyboard';
import { cx } from 'cva';
import Picker from '@emoji-mart/react';
import 'react-simple-keyboard/build/css/index.css';
import './index.css';

export interface KioskEditorModalProps
  extends ComponentPropsWithoutRef<'dialog'> {
  /** className prop for the element that is used to display the semi-transparent background gradient
   *
   * - if a value is provided here, the className is replaced, not appended
   * - default className is `'bg-gradient-blue-to-purple opacity-90'`
   */
  backgroundClassName?: string;
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  keyboard: React.MutableRefObject<undefined>;
  /** for situations where the user needs to `edit` some value */
  valueToEdit?: string;
  /**
   * Function to handle the onClick event for the Confirm button in the modal
   * @param userInput the input value at the time the user taps the `Confirm` button
   * @param closeModal a callback function that will trigger the Modal <dialog>'s `.close()` method
   */
  handleConfirm: (userInput: string) => void;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  input: string;
  cursorPointer: number;
  setCursorPointer: React.Dispatch<React.SetStateAction<number>>;
  charMap?: Map<number, number>;
  setCharMap?: React.Dispatch<React.SetStateAction<Map<number, number>>>;
  type: 'message' | 'text' | 'number';
  tablet: boolean;
  changeFontSize?: (messageInput: string, charMap: Map<number, number>) => void;
}

enum kbNamedKey {
  CONFIRM = '{confirm}',
  LOCK = '{lock}',
  SHIFT = '{shift}',
  SPACE = '{space}',
  ENTER = '{enter}',
  BACKSPACE = '{bksp}',
  TAB = '{tab}',
  DOTCOM = '.com',
}

/**
 * This component is designed to used with the `useModal` hook; it must be passed as the child to the `Modal`.
 */
export const KioskEditorModal = ({
  className,
  backgroundClassName,
  children,
  inputRef: controlledChildRef,
  valueToEdit = '',
  handleConfirm,
  setInput,
  input,
  keyboard,
  setCursorPointer,
  cursorPointer,
  charMap,
  setCharMap,
  tablet,
  type,
  changeFontSize,
  ...dialogProps
}: KioskEditorModalProps) => {
  const [previousButton, setPreviousButton] = useState<
    kbNamedKey.SHIFT | null | (string & Record<never, never>) // this pattern enables autocompletion while still allowing any string value
  >(null);
  const [lock, setLock] = useState(false);
  const [autoCap, setAutoCap] = useState(type === 'message');

  const sortCharMap = (map: Map<number, number>) => {
    return new Map([...map.entries()].sort((a, b) => a[0] - b[0]));
  };

  const isMaxLengthReached = () => (charMap?.size || 0) > 225;

  const updateCharMap = (
    startPos: number,
    length: number,
    type: 'message' | 'text' | 'number',
    newValue: string
  ) => {
    if (!charMap || !setCharMap) return;
    const newMap = new Map<number, number>();
    charMap.forEach((value, key) => {
      newMap.set(key >= startPos ? key + length : key, value);
    });
    if (type === 'message' || length === 1) newMap.set(startPos, length);
    else for (let i = 0; i < length; i++) newMap.set(startPos + i, 1);
    setCharMap(sortCharMap(newMap));
    if (changeFontSize) changeFontSize(newValue, newMap);
  };

  const removeCharMap = (
    startPos: number,
    length: number,
    newValue: string
  ) => {
    if (!charMap || !setCharMap) return;
    const newMap = new Map<number, number>();
    charMap.forEach((value, key) => {
      if (key === startPos) return;
      newMap.set(key > startPos ? key - length : key, value);
    });
    setCharMap(sortCharMap(newMap));
    if (changeFontSize) changeFontSize(newValue, newMap);
  };

  const detectSentenceStart = (inputValue: string) => {
    const trimmedInput = inputValue.trimEnd();
    // Check if the last character is a sentence-ending punctuation
    setAutoCap(/[.!?]\s?$/.test(trimmedInput));
  };

  const onKeyPress = (button: string) => {
    if (button === kbNamedKey.SHIFT) {
      setPreviousButton(prevButton =>
        prevButton === kbNamedKey.SHIFT ? null : kbNamedKey.SHIFT
      );
    } else {
      setPreviousButton(null);
    }
    let newValue = input;
    let newPosition = cursorPointer;
    switch (button) {
      case kbNamedKey.CONFIRM:
      case kbNamedKey.SHIFT:
        break;
      case kbNamedKey.LOCK:
        setLock(lock => !lock);
        break;
      case kbNamedKey.ENTER:
        if (isMaxLengthReached()) return;
        if (controlledChildRef.current instanceof HTMLTextAreaElement) {
          newValue =
            input.slice(0, cursorPointer) + '\n' + input.slice(cursorPointer);
          newPosition = cursorPointer + 1;
          updateCharMap(cursorPointer, 1, type, newValue);
        }
        break;
      case kbNamedKey.DOTCOM:
        if (isMaxLengthReached()) return;
        newValue =
          input.slice(0, cursorPointer) + button + input.slice(cursorPointer);
        newPosition = cursorPointer + 4;
        updateCharMap(cursorPointer, 4, type, newValue);
        break;
      case kbNamedKey.TAB:
        if (isMaxLengthReached()) return;
        newValue =
          input.slice(0, cursorPointer) + '    ' + input.slice(cursorPointer);
        newPosition = cursorPointer + 4;
        updateCharMap(cursorPointer, 4, type, newValue);
        break;
      case kbNamedKey.SPACE:
        if (isMaxLengthReached()) return;
        newValue =
          input.slice(0, cursorPointer) + ' ' + input.slice(cursorPointer);
        newPosition = cursorPointer + 1;
        updateCharMap(cursorPointer, 1, type, newValue);
        break;
      case kbNamedKey.BACKSPACE:
        if (cursorPointer === 0) return;
        if (!charMap || !setCharMap) {
          newValue =
            input.slice(0, cursorPointer - 1) + input.slice(cursorPointer);
          newPosition = cursorPointer - 1;
          break;
        }
        // eslint-disable-next-line no-case-declarations
        const multiCharPos = Array.from(charMap.keys())
          .reverse()
          .find(pos => pos < cursorPointer);
        if (
          multiCharPos !== undefined &&
          cursorPointer <= multiCharPos + charMap.get(multiCharPos)!
        ) {
          const multiCharLength = charMap.get(multiCharPos)!;
          newValue =
            input.slice(0, multiCharPos) +
            input.slice(multiCharPos + multiCharLength);
          newPosition = multiCharPos;
          removeCharMap(multiCharPos, multiCharLength, newValue);
        } else {
          newValue =
            input.slice(0, cursorPointer - 1) + input.slice(cursorPointer);
          newPosition = cursorPointer - 1;
        }
        break;
      default:
        if (isMaxLengthReached()) return;
        newValue =
          input.slice(0, cursorPointer) + button + input.slice(cursorPointer);
        newPosition = cursorPointer + button.length;
        updateCharMap(cursorPointer, button.length, type, newValue);
    }
    if (type === 'number' && isNaN(Number(newValue))) return;
    if (type === 'message') detectSentenceStart(newValue);
    setInput(newValue);
    setCursorPointer(newPosition);
  };

  const bindKeyActions = (kb: SimpleKeyboard | undefined) => {
    if (!kb) return;

    const spaceKey = kb.getButtonElement(kbNamedKey.SPACE);
    if (spaceKey && !('length' in spaceKey)) {
      spaceKey.setAttribute(
        'aria-label',
        'Space bar; add a space character to your current input'
      );
    }

    const enterKey = kb.getButtonElement(kbNamedKey.ENTER);
    if (
      enterKey &&
      !('length' in enterKey) &&
      // if we're controlling an input element, allow to confirm on enter key press
      controlledChildRef.current instanceof HTMLInputElement
    ) {
      enterKey.addEventListener('touchstart', e => {
        e.preventDefault();
        handleConfirm(controlledChildRef?.current?.value || '');
      });
      enterKey.addEventListener('click', e => {
        e.preventDefault();
        handleConfirm(controlledChildRef?.current?.value || '');
      });
    }

    const confirmKey = kb.getButtonElement(kbNamedKey.CONFIRM);
    if (confirmKey && !('length' in confirmKey)) {
      confirmKey.addEventListener('touchstart', e => {
        e.preventDefault();
        handleConfirm(controlledChildRef?.current?.value || '');
      });
      confirmKey.addEventListener('click', e => {
        e.preventDefault();
        handleConfirm(controlledChildRef?.current?.value || '');
      });
    }

    // const lockKey = kb.getButtonElement(kbNamedKey.LOCK);
    // if (lockKey && !('length' in lockKey)) {
    //   lockKey.addEventListener('touchstart', () => setLock(lock => !lock));
    // }
    // const shiftKey = kb.getButtonElement(kbNamedKey.SHIFT);
    // if (shiftKey && !('length' in shiftKey)) {
    //   shiftKey.addEventListener('touchstart', () => {
    //     if (!lock && previousButton !== kbNamedKey.SHIFT) {
    //       setPreviousButton(kbNamedKey.SHIFT);
    //     }
    //   });
    // }
    const shiftKeys = kb.getButtonElement(kbNamedKey.SHIFT);
    // subtle difference here; there is more than one shift key
    if (shiftKeys && 'length' in shiftKeys) {
      shiftKeys.map(key => {
        key.addEventListener('touchstart', () => {
          /* *technically* we are lying to ourselves, because this logic makes it so that if you press the `kbNamedKey.SHIFT` key twice in a row, we don't update `previousButton` to be equal to `kbNamedKey.SHIFT` for that second keypress. This is because our `onKeypress` function above auto-resets the `previousButton` state to `null` if on the next render it finds that the value is `kbNamedKey.SHIFT`. But, this does get our logic to work properly even if it's a little unintuitive! */
          if (!lock && previousButton !== kbNamedKey.SHIFT) {
            setPreviousButton(kbNamedKey.SHIFT);
          }
        });
      });
    }
  };

  const defaultLayout = [
    '~ ! @ # $ % ^ & * ( ) ?',
    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    '{tab} q w e r t y u i o p [ ] \\',
    "{lock} a s d f g h j k l ; ' {enter}",
    '{shift} z x c v b n m , . / {shift}',
    '.com @ {space} {confirm}',
  ];

  const shiftLayout = [
    '~ ! @ # $ % ^ & * ( ) ?',
    '` 1 2 3 4 5 6 7 8 9 0 _ + {bksp}',
    '{tab} Q W E R T Y U I O P { } |',
    '{lock} A S D F G H J K L : " {enter}',
    '{shift} Z X C V B N M < > ? {shift}',
    '.com @ {space} {confirm}',
  ];

  const autoCapLayout = [
    '~ ! @ # $ % ^ & * ( ) ?',
    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
    '{tab} Q W E R T Y U I O P [ ] \\',
    "{lock} A S D F G H J K L ; ' {enter}",
    '{shift} Z X C V B N M , . / {shift}',
    '.com @ {space} {confirm}',
  ];

  const tabletDefaultLayout = [
    "; ' - ! # $ % & * ( ) +",
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    '{tab} q w e r t y u i o p',
    '{lock} a s d f g h j k l {enter}',
    '{shift} z x c v b n m , . /',
    '.com @ {space} {confirm}',
  ];

  const tabletShiftLayout = [
    ': " \\ ^ [ ] { } ` ~ _ =',
    '! @ # $ % ^ & * ( ) {bksp}',
    '{tab} Q W E R T Y U I O P',
    '{lock} A S D F G H J K L {enter}',
    '{shift} Z X C V B N M < > ?',
    '.com @ {space} {confirm}',
  ];

  const tabletAutoCapLayout = [
    "; ' - ! # $ % & * ( ) +",
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    '{tab} Q W E R T Y U I O P',
    '{lock} A S D F G H J K L {enter}',
    '{shift} Z X C V B N M , . /',
    '.com @ {space} {confirm}',
  ];

  const display = {
    '{confirm}': 'Confirm',
  };

  const tabletDisplay = {
    '{confirm}': 'Confirm',
    '{bksp}': '⌫', // Change to a more intuitive backspace symbol
    '{enter}': '⏎', // Change to a more intuitive enter symbol
    '{shift}': '⇧', // Change to a more intuitive shift symbol
    '{lock}': '⇪',
  };

  /** memoized the keyboard props that never change to avoid unnecessary re-renders */
  const staticKeyboardProps = useMemo(
    () => ({
      disableButtonHold: true,
      useButtonTag: true,
      layout: {
        default: tablet ? tabletDefaultLayout : defaultLayout,
        shift: tablet ? tabletShiftLayout : shiftLayout,
        autoCap: tablet ? tabletAutoCapLayout : autoCapLayout,
      },
      mergeDisplay: true, // allows us to only customize one of the named key display names
      // since '{confirm}' is the only custom named key, that's the only one we want to provide a display name for
      display: tablet ? tabletDisplay : display,
      /** initialize the internal state of the keyboard lib with our starting input value */
      onInit: (kb: SimpleKeyboard | undefined) => {
        if (kb) {
          kb.setInput(input);
        }
      },
    }),
    // no need to regenerate based on `input`, as it's only used in a method that's called once upon initialization
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEmojiSelect = (emoji: any) => {
    if (isMaxLengthReached()) return;
    const emojiNative = emoji.native;
    const newValue =
      input.slice(0, cursorPointer) + emojiNative + input.slice(cursorPointer);
    const newPosition = cursorPointer + emojiNative.length;
    updateCharMap(cursorPointer, emojiNative.length, type, newValue);
    setInput(newValue);
    setCursorPointer(newPosition);
  };

  return (
    <>
      <dialog
        className={cx(
          'text-primary animate-scaleTo100 absolute top-0 z-30 h-full max-h-screen w-full max-w-[100vw] bg-transparent p-0',
          className
        )}
        {...dialogProps}
      >
        <div
          // full-screen element used to display the gradient background
          className={
            backgroundClassName ??
            'bg-gradient-blue-to-purple absolute left-0 top-0 h-full w-full opacity-90'
          }
        />
        <div className='relative flex h-full w-full flex-col items-center justify-center'>
          <div className='absolute bottom-[56%] z-10 flex w-[90%] max-w-[88%] flex-col items-center justify-center'>
            {children}
          </div>
          <div
            className={
              tablet
                ? 'simple-keyboard-tablet shadow-far2 z-20 h-fit w-[94%]'
                : 'shadow-far2 z-20 h-fit w-[94%]'
            }
            style={{ marginTop: '150px' }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              onKeyPress={onKeyPress}
              newLineOnEnter={
                controlledChildRef.current instanceof HTMLDivElement
              }
              layoutName={
                lock || previousButton === kbNamedKey.SHIFT
                  ? 'shift'
                  : autoCap
                  ? 'autoCap'
                  : 'default'
              }
              buttonTheme={[
                {
                  class: '!bg-primary-10 flex-grow-0 max-w-[130px]',
                  buttons: kbNamedKey.CONFIRM,
                },
                {
                  class: 'hg-button-dotcom',
                  buttons: kbNamedKey.DOTCOM,
                },
                {
                  class: lock ? '!bg-primary-10' : 'bg-surface-primary',
                  buttons: kbNamedKey.LOCK,
                },
              ]}
              onRender={bindKeyActions}
              {...staticKeyboardProps}
            />
            {type === 'message' && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2rem',
                }}
              >
                <Picker
                  onEmojiSelect={handleEmojiSelect}
                  maxFrequentRows={0}
                  previewPosition='none'
                  searchPosition='none'
                  skinTonePosition='none'
                  theme='light'
                  perLine='14'
                  set='twitter'
                  emojiButtonSize='72'
                  emojiSize='48'
                />
              </div>
            )}
          </div>
        </div>
      </dialog>
    </>
  );
};

export const renderWithCursor = ({
  value,
  cursorPosition,
  placeholder = '',
  blinkingHeight = '90%',
}: {
  value: string;
  cursorPosition: number;
  placeholder?: string;
  blinkingHeight?: string;
}) => {
  const cursorElement = (
    <span
      key='cursor'
      className='blinking-line'
      style={{ height: blinkingHeight }}
    ></span>
  );
  if (value === '') {
    return (
      <p className='char placeholder'>
        {cursorElement}
        {placeholder || ''}
      </p>
    );
  }

  const renderedElements = [];
  let before = value.slice(0, cursorPosition);
  let after = value.slice(cursorPosition);

  renderedElements.push(before);
  renderedElements.push(cursorElement);
  renderedElements.push(after);

  return <p className='char'>{renderedElements}</p>;
};
