import { useState, useEffect, useRef } from 'react';
import SignaturePad, { PointGroup } from 'signature_pad';
import type { ComponentPropsWithoutRef, RefObject } from 'react';

export interface KioskEditSignatureImageModalProps
  extends ComponentPropsWithoutRef<'dialog'> {
  /** className prop for the element that is used to display the semi-transparent background gradient
   *
   * - if a value is provided here, the className is replaced, not appended
   * - default className is `'bg-gradient-blue-to-purple opacity-90'`
   */
  backgroundClassName?: string;
  controlledChildRef: RefObject<HTMLInputElement | HTMLDivElement | null>;
  /** for situations where the user needs to `edit` some value */
  /**
   * Function to handle the onClick event for the Confirm button in the modal
   * @param userInput the input value at the time the user taps the `Confirm` button
   * @param closeModal a callback function that will trigger the Modal <dialog>'s `.close()` method
   */
  handleConfirm: (signatureImg: string) => void;
  images?: string;
}

const SignaturePadUI = ({
  controlledChildRef,
  handleConfirm,
  children,
  images,
  ...dialogProps
}: KioskEditSignatureImageModalProps) => {
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
  const signatureRedoArray = useRef<PointGroup[]>([]);

  const readyPad = () => {
    let wrapper = document.getElementById('signature-pad');
    let canvas = wrapper?.querySelector('canvas') as HTMLCanvasElement;
    canvas.width = 550;
    canvas.height = 225;
    canvas.getContext('2d')?.scale(1, 1);
    let tempSignaturePad = new SignaturePad(canvas, {
      minWidth: 5,
      maxWidth: 5,
      penColor: 'rgb(0, 0, 0)',
    });
    setSignaturePad(tempSignaturePad);
  };

  const handleSave = () => {
    if (!signaturePad) return;
    handleConfirm(signaturePad.toDataURL());
  };

  const handleUndo = () => {
    if (!signaturePad) return;
    const signatureData = signaturePad.toData();
    const signatureRedoData = [...signatureData]; // original data

    if (signatureData.length === 0) return;
    signatureData.pop(); // remove the last dot or line
    signaturePad.fromData(signatureData);
    const signatureRemovedData =
      signatureRedoData[signatureRedoData.length - 1];
    signatureRedoArray.current.push(signatureRemovedData);
  };

  useEffect(() => {
    readyPad();
  }, []);

  return (
    <dialog
      className='text-primary animate-scaleTo100 absolute top-0 z-30 h-full max-h-screen w-full max-w-[100vw] bg-transparent p-0'
      {...dialogProps}
    >
      <div className='relative flex h-full w-full flex-col items-center justify-center'>
        <div className='absolute bottom-[56%] z-10 flex w-[90%] max-w-[88%] flex-col items-center justify-center'>
          <div className='bg-surface-primary relative flex aspect-[5/7] w-full flex-col p-20'>
            <div
              className='m-bs-auto relative z-30 flex h-96 max-h-96 w-full flex-col justify-center '
              id='signature-pad'
            >
              <canvas
                className='signature-canvas rounded-md border-4 border-dashed'
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              />
              <div className='mt-8 flex justify-around'>
                <button
                  onClick={handleSave}
                  className='bottom-4 right-4 w-1/5 rounded-sm border-2 border-black p-3'
                >
                  Save
                </button>
                <button
                  onClick={handleUndo}
                  className='bottom-4 right-4 w-1/5 rounded-sm border-2 border-black p-3'
                >
                  Undo
                </button>
              </div>
            </div>
            <img
              alt='preview_interior_image'
              src={images ?? undefined}
              className='absolute inset-0 z-10 aspect-[5/7] h-full w-full'
            />
            <div
              id='card_interior_shadow'
              className='absolute inset-0 z-20 h-full w-full'
              style={{
                backgroundSize: 'cover',
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='7' viewBox='0 0 5 7' className='absolute inset-0 z-20 h-full w-full' %3E%3Cpath opacity='0.05' d='M0 0V7H4.5L0 0Z' fill='url(%23card_shadow_diagonal_gradient)' /%3E%3Cdefs%3E%3ClinearGradient id='card_shadow_diagonal_gradient' x1='2.25' y1='0' x2='2.25' y2='7' gradientUnits='userSpaceOnUse' %3E%3Cstop /%3E%3Cstop offset='1' stop-color='%23D9D9D9' stop-opacity='0' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
              }}
            />
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default SignaturePadUI;
