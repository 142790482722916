import Link from 'next/link';
// import { useRouter } from 'next/router';
import { cx } from 'cva';
import { useContext, useMemo } from 'react';
import { Badge, Logo, SVG } from '@inkd/ui';
import { useCartState } from '@/utils';
import { CardsAfterDarkContext } from '@/context/cardsAfterDark';

interface BrowsingFlowNavbarProps {
  flow: 'browsing';
  handleCancelPersonalization?: never;
}
interface PersonalizationFlowNavbarProps {
  flow: 'personalization';
  handleCancelPersonalization: () => void;
}
interface CheckoutFlowNavbarProps {
  flow: 'checkout';
  handleCancelPersonalization?: never;
}

type NavbarProps =
  | BrowsingFlowNavbarProps
  | PersonalizationFlowNavbarProps
  | CheckoutFlowNavbarProps;

export function Navbar({ flow, handleCancelPersonalization }: NavbarProps) {
  const context = useContext(CardsAfterDarkContext);

  const handleCardsAfterDark = () => {
    context?.setCardsAfterDark(!context?.isCardsAfterDark || false);
  };

  return (
    <nav
      aria-label='Main navigation'
      className={cx(
        'sticky top-0 z-50',
        context?.isCardsAfterDark
          ? 'bg-gradient-blue-to-purple'
          : flow === 'browsing'
          ? 'bg-gradient-blue-to-yellow'
          : flow === 'personalization'
          ? 'bg-gradient-blue-to-purple'
          : flow === 'checkout'
          ? 'bg-gradient-lightblue-to-blue'
          : undefined
      )}
    >
      <div className='mx-auto grid max-w-screen-md grid-cols-[1fr_2fr_1fr] items-center p-3'>
        {/* hiding for now as it still has 1 or 2 bugs
        <button
          className='h-max w-max rounded-full bg-white p-2'
          onClick={() =>
            flow === 'personalization' ||
            !!router.asPath.match(/^\/checkout\/cart\?cIm=.*$/)
              ? router.push('/')
              : router.back()
          }
          aria-label='Back'
        >
          <SVG name='ArrowLeft' className='h-6 w-6' />
        </button> */}
        {flow === 'personalization' ? (
          <button
            className='justify-self-start'
            aria-label='Cancel personalization of card'
            onClick={handleCancelPersonalization}
          >
            <Logo aria-label="Ink'd logo" />
          </button>
        ) : (
          <Link
            href='/'
            className='inline-block justify-self-start'
            aria-label='Home screen'
          >
            <Logo aria-label="Ink'd logo" />
          </Link>
        )}
        <div className='justify-self-center'>
          <p
            style={{
              fontFamily: "LazyDog, 'Courier New', Courier, monospace",
              fontSize: '20px',
            }}
          >
            {context?.isCardsAfterDark
              ? 'SWITCH TO REGULAR'
              : 'SWITCH TO CARDS AFTER DARK'}
          </p>
          <div className='text-center'>
            <label className='switch small'>
              <input
                type='checkbox'
                onClick={handleCardsAfterDark}
                onChange={handleCardsAfterDark}
                checked={context?.isCardsAfterDark || false}
              />
              <span className='slider'></span>
            </label>
          </div>
        </div>
        {flow === 'browsing' ? (
          <BrowsingFlow />
        ) : flow === 'personalization' ? (
          <button
            className='h-max w-max justify-self-end rounded-full bg-white p-2'
            aria-label='Cancel personalization of card'
            onClick={handleCancelPersonalization}
          >
            <SVG name='X' className='h-6 w-6' />
          </button>
        ) : (
          <span aria-hidden='true' />
        )}
      </div>
    </nav>
  );
}

function BrowsingFlow() {
  const { cart } = useCartState();
  const cartQuantity = useMemo(() => cart.length, [cart]);

  return (
    <Link
      href='/checkout/cart'
      className='relative h-max w-max justify-self-end rounded-full bg-white p-2'
    >
      <span className='sr-only'>Cart</span>
      <SVG name='Cart' />
      {cartQuantity > 0 ? (
        <Badge
          aria-label={`${cartQuantity} item${
            cartQuantity === 1 ? '' : 's'
          } in cart`}
          className='absolute -right-1 -top-1'
        >
          {cartQuantity}
        </Badge>
      ) : null}
    </Link>
  );
}
